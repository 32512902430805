
import { Component, Vue } from "vue-property-decorator";

import { apiRequest } from "@/api/apiRequest";
import MaintenancePage from "@/components/MaintenancePage/MaintenancePage.vue";
import Snackbar from "@/components/shared/Snackbar.vue";
import SuccessDialog from "@/components/shared/SuccessDialog.vue";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { sleep } from "@/utils/sleep";

@Component({
  components: {
    MaintenancePage,
    Snackbar,
    SuccessDialog,
  },
})
export default class App extends Vue {
  snackbar = false;
  snackbarText = "";
  snackbarType = "";
  $userActivityTracker: any;

  async created() {
    document.title = process.env.VUE_APP_BRAND_DOMAIN || "/";
    snackBarEventBus.$on(snackBarEventName, async (message: { message: string; type: string }) => {
      this.snackbar = false;
      await sleep(1);
      this.snackbarText = message.message;
      this.snackbarType = message.type;
      this.snackbar = true;
    });

    apiRequest.interceptors.response.use(
      (r: any) => r,
      async (err: any) => {
        if (err?.response?.status === 401 && err.config && !err.config.__isRetryRequest) {
          err.config.__isRetryRequest = true;
          return apiRequest(err.config);
        } else {
          if (
            err?.response?.status === 401 ||
            (err?.response?.status === 400 && err?.response?.data?.error?.code === 400)
          ) {
            this.$userActivityTracker.triggerInactive();
          } else throw err;
        }
      },
    );
    apiRequest.interceptors.request.use(
      (config: any) => {
        return config;
      },
      (error: any) => Promise.reject(error),
    );
  }
}

//  helper for mobile
const documentHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
};
window.addEventListener("resize", documentHeight);
documentHeight();
