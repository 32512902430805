import Vue from "vue";
import VueRouter from "vue-router";
import { RouteConfig } from "vue-router/types/router";

Vue.use(VueRouter);

export const routes: RouteConfig[] = [
  {
    path: "/",
    component: () => import("../views/Landing/View.vue"),
  },
  {
    path: "/brokers",
    component: () => import("../views/Landing/Brokers.vue"),
  },
  {
    path: "/employers",
    component: () => import("../views/Landing/Employers.vue"),
  },
  {
    path: "/patients",
    component: () => import("../views/Landing/Patients.vue"),
  },
  {
    path: "/about",
    component: () => import("../views/Landing/About.vue"),
  },
  {
    path: "/support",
    component: () => import("../views/Landing/Support.vue"),
  },
  {
    path: "/consent",
    component: () => import("../views/Terms/Consent.vue"),
  },
  {
    path: "/terms",
    component: () => import("../views/Terms/Terms.vue"),
  },
  {
    path: "/*",
    redirect: "/",
  },
];
