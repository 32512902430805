import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "viewerjs/dist/viewer.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

import * as moment from "moment-timezone";
import VueViewer from "v-viewer";
import Vue from "vue";
import VueCookies from "vue-cookies";
import VueFileAgent from "vue-file-agent";
import VueRouter from "vue-router";
import Vuelidate, { validationMixin } from "vuelidate";

import initI18n from "@/plugins/i18n";
import vuetify from "@/plugins/vuetify";

import App from "./App.vue";
import { routes } from "./router";

export const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
Vue.use(VueViewer);

Vue.prototype.$moment = moment;
Vue.config.productionTip = false;
Vue.use(VueFileAgent);
Vue.use(VueCookies);
Vue.use(Vuelidate);

Vue.directive("blur", {
  inserted: function (el) {
    el.onfocus = (ev) => ev && ev.target && el.blur();
  },
});

new Vue({
  router,
  vuetify,
  i18n: initI18n(),
  mixins: [validationMixin],
  render: (h) => h(App),
}).$mount("#app");
